
<app-page-loader></app-page-loader>

<main class="home-main">
  <div class="hero-section">
    <video autoplay loop  [muted]="'muted'" plays-inline id="backgroundVideo" class="background-video">
      <source src="https://zigwik.com/category_default_images/home-hero-bg.mp4" type="video/mp4">
    </video>
    <nav>
      <app-header [showWhiteLogo]="true"></app-header>
    <app-header-sticky></app-header-sticky>
    <app-header-mobile></app-header-mobile>
    </nav>
    <section class="row align-items-center main-container container">
      <div class="header-left">
        <div class="header-main-content">
          <div class="header-content">
            <div class="home-main-header">
            <h1 class="has-text-white">
              First Marketplace for Time, <br />
              the most valuable asset.
            </h1>
            </div>
            <p class="has-text-white">
              Welcome to Zigwik, the first Marketplace for Time.
              <br />
              Zigwik is an Ocean of Time slots from many Experts, Professionals, Educators, and more.
            </p>
          </div>
          <div class="ai_enable-platform floating-cards row align-items-center">
            <div class="content-text">
              <h4 class="header-left-title"><i class="fa fa-calendar" aria-hidden="true"></i> Smart Scheduling</h4>
              <p class="header-left-subtitle">
                World's most advance Time booking Platform.
              </p>
            </div>
          </div>
        </div>
        <section class="search-filter-container">
          <div class="search-filter">
            <app-filter-serach></app-filter-serach>
          </div>
          
          <section class="safe_secure floating-cards row align-items-center" >
            <div class="content-text">
              <h4 class="header-left-title"><i class="fas fa-robot"></i> Safe and secure with AI</h4>
              <p class="header-left-subtitle">
                AI monitors what is happening in the Platform.
              </p>
            </div>
          </section>
        </section>
      </div>
    </section>
  </div>
  <app-home-info data-aos="fade-zoom-in" data-aos-offset="100"
  data-aos-easing="ease-in-sine"></app-home-info>
  
  <!-- Popular Products -->
  <ng-container >
    <section class="home-custom container fade-right">
      <div class="">
        <div class="justify-content-between align-items-center" data-aos="fade-zoom-in" data-aos-offset="100"
        data-aos-easing="ease-in-sine">
          <div class="header-container">
            <h2 class="pro-heading-title header-text">
              Popular Services
            </h2>
            <p class="sub-text-value">
              Most viewed and all-time top-selling services
            </p>
          </div>
          <div class="filter-container">
            <ng-container *ngFor="let item of catogeryList">
              <button class="filter-container-btn" [ngClass]="selectedFilter == item ? 'active' : '' " (click)="selectedFilter = item; getPopularProductList()" > {{item}} </button>
            </ng-container>
          </div>
        </div>
        <div class="row cards" *ngIf="homeProducts?.length;else noproducts">
          <div
            class="col-12 col-md-6 col-lg-3 col-xl-3 cards-list"
            *ngFor="let product of homeProducts"
          >
            <app-product-card [data]="product"></app-product-card>
          </div>
        </div>
        <ng-template #noproducts>
          <p class="no-services">No Popular services available</p>
        </ng-template>
      </div>
    </section>
  </ng-container>

  <!-- find talent section -->

  <section class="telent_section py-0" data-aos="fade-zoom-out" data-aos-offset="100"
  data-aos-easing="ease-in-sine">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-xl-5 ">
          <div class="mb30">
            <h5 class="text-thm">For You</h5>
            <h2 class="title">Find the best offers from others</h2>
          </div>
          <p class="text">Work with the largest network of independent professionals and <br class="d-none d-lg-block"> get things done—from quick turnarounds to big transformations.</p>
          <button class="them-btn" routerLink="/contact-us">Contact Us</button>
        </div>
        <div class="col-lg-5 col-xl-4 offset-xl-1 position-relative">
          <div class="info_card_container position-relative">
            <div class="info_card_list">
              <ul class="mb-0">
                <li class="text-white fw500"><i class="fa fa-check" aria-hidden="true"></i>The best for every budget</li>
                <li class="text-white fw500"><i class="fa fa-check" aria-hidden="true"></i>Quality work done quickly</li>
                <li class="text-white fw500"><i class="fa fa-check" aria-hidden="true"></i>Protected payments, every time</li>
                <li class="text-white fw500 mb-0"><i class="fa fa-check" aria-hidden="true"></i>24/7 support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <app-footer-mobile data-aos-offset="300"
  data-aos-easing="ease-in-sine"></app-footer-mobile>
  <app-footer data-aos-offset="300"
  data-aos-easing="ease-in-sine"></app-footer>
  <app-accept-cookie data-aos-offset="300"
  data-aos-easing="ease-in-sine"></app-accept-cookie>
  
  <app-product-modal ></app-product-modal>
  <app-back-to-top></app-back-to-top>
</main>
