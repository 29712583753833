import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header-categories',
  templateUrl: './header-categories.component.html',
  styleUrls: ['./header-categories.component.scss']
})
export class HeaderCategoriesComponent implements OnInit {
  allCategoryList: any;
  @Input() isSticky:boolean = false;
  constructor(private authService:AuthService, private router:Router) { }

  ngOnInit(): void {
    this.authService.allCatogeryList.subscribe(res => {
      this.allCategoryList = res;
      // console.log(this.allCategoryList, "ALL CAT LIST")
    })
  }

redrictToSearch(data, url, category?, subCategory1?, subCategory2?):void{
  let filterList = {
    categoryGroup:data?.name,
    category:category,
    subCategory1:subCategory1,
    subCategory2:subCategory2
  }
  this.authService.filetrData.next({filterList} )
  this.router.navigate(['search'],{
    state:{
      response:{url:url, data}
    }
  })
  // console.log(url, data, 'From HOME')
}



}
