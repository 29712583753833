import { Component, OnInit, Input } from "@angular/core";

import { KatoraService } from "../../services/katora.service";
import { SessionConstants } from "src/app/constants/app.constants";
import { SessionService } from "src/app/services/session.service";
import { ProductLikeMutation } from "src/app/services/graphql/productLike.graphql";
import { Apollo } from "apollo-angular";
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: "app-product-card",
  // templateUrl: "./product-card.component.html",
  templateUrl: "./product-card.temp.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent implements OnInit {
  @Input() data: any;
  likes: string;
  sessionUserData: any;
  userLoginCheck: boolean;
  openModelBox:boolean = false;
  defaultProfile:string = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
  profileURL:string = SessionConstants.CONST_PROFILE_IMAGE_MASTER_URL

  ngOnInit() {
    this.likes = this.data.products_liked;
    // console.log(this.data);
    

    if(this.data.products_liked > 999) {
      this.likes = (Math.floor((this.data.products_liked / 1000) * 100) / 100) + "K";
    }

    if(this.sessionService.checkKey(SessionConstants.CONST_SESSION_TOKEN_KEYWORD)) {
      this.sessionUserData = this.sessionService.get(SessionConstants.CONST_SESSION_TOKEN_KEYWORD);
    }

    if(this.sessionUserData !== undefined && this.sessionUserData !== null) {
      this.userLoginCheck = this.sessionUserData["valid_user"];
    }
  }

  constructor(
    private apollo: Apollo,
    private katoraService: KatoraService, 
    private sessionService: SessionService,
    private modalService: NgbModal,
    private productLikeMutation: ProductLikeMutation,
    private toastr: ToastrService
    ) {}

  onAddCart(product: any) {
    this.katoraService.addProductToCart(product);
  }

  onAddLike(productId: Number) {
    var email = this.sessionUserData["email"];
    var idToken = this.sessionUserData["id_token"];

    this.apollo
      .mutate({
        mutation: this.productLikeMutation.document,
        variables: {
          email: email,
          idToken: idToken,
          productId: productId
        },
      })
      .subscribe(
        ({ data }) => {
          this.toast("Thank you for liking the offer.");
        }
      );
  }

  onAddWishlist(product: any) {
    this.katoraService.addProductToWishlist(product);
  }

  toast(msg){
    this.toastr.success(msg);
  }

  openModel(){
    this.openModelBox = !this.openModelBox
  }
}
