import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(
    private cookieService: CookieService
  ) {}

  set(key: string, data: any): void {
    this.setCookie(key, data);
  }

  setSession(key: string, data: any): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error("Error saving to sessionStorage", e);
    }
  }

  setCookie(key: string, data: any, accurate_expiry = false, expiry_minutes = 0): void {
    try {
      if(accurate_expiry == false) {
        this.cookieService.set(key, JSON.stringify(data), { expires: 10, path: '/', sameSite: 'Strict' });
      } else {
        var date = new Date();
        date.setTime(date.getTime() + (expiry_minutes * 60 * 1000));
        this.cookieService.set(key, JSON.stringify(data), { expires: date, path: '/', sameSite: 'Strict' });
      }
      
      // var date = new Date();
      // date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
      // document.cookie = key+"="+encodeURIComponent(JSON.stringify(data))+"; expires="+date.toUTCString()+"; path=/";
    } catch (e) {
      console.error("Error saving to local storage", e);
    }
  }



  setLocal(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error("Error saving to local storage", e);
    }
  }

  deleteLocal(key: string) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch(e) {
      return false;
    }
  }

  checkKey(key: string): boolean {
    // if(sessionStorage.getItem(key) === null) {
    //   return false;
    // }
    return this.cookieService.check(key);
  }

  checkSessionKey(key: string): boolean {
    if(sessionStorage.getItem(key) === null) {
      return false;
    }

    return true;
  }

  getLocal(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key)) 
    } catch(e) {
      return null;
    }
  }

  getCookie(key: string) {
    if(this.cookieService.check(key) == true) {
      return JSON.parse(this.cookieService.get(key));
    }
    
    return null;
    // let name = key + "=";
    // let decodedCookie = decodeURIComponent(document.cookie);
    // let ca = decodedCookie.split(';');
    // for(let i = 0; i <ca.length; i++) {
    //   let c = ca[i];
    //   while (c.charAt(0) == ' ') {
    //     c = c.substring(1);
    //   }
    //   if (c.indexOf(name) == 0) {
    //     return c.substring(name.length, c.length);
    //   }
    // }
    // return null;
  }

  get(key: string) {
    return this.getCookie(key);
  }

  getSession(key: string) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      console.error("Error getting data from sessionStorage", e);
      return null;
    }
  }

  getKeyValues(sessionKey: string, key: string) {
    return this.getCookieKeyValues(sessionKey, key);
  }

  getSessionKeyValues(sessionKey: string, key: string) {
    try {
      let sessionData = null;
      if(sessionStorage.getItem(sessionKey) !== "undefined" && 
      sessionStorage.getItem(sessionKey) !== null) {
        sessionData = JSON.parse(
          JSON.parse(sessionStorage.getItem(sessionKey))
        );
      }
      
      if(sessionData != null) {
        return sessionData[key];
      }
      return null;
    } catch (e) {
      console.error("Error getting data from sessionStorage", e);
      return null;
    }
  }

  getCookieKeyValues(sessionKey: string, key: string) {
    try {
      let cookieData = null;
      if(this.getCookie(sessionKey) !== "undefined" && 
      this.getCookie(sessionKey) !== null) {
        cookieData = this.getCookie(sessionKey);
      }
      if(cookieData != null) {
        return cookieData[key];
      }
      return null;
    } catch (e) {
      console.error("Error getting data from cookieStorage", e);
      return null;
    }
  }

  getLocalKeyValues(sessionKey: string, key: string) {
    try {
      let localData = null;
      if(localStorage.getItem(sessionKey) !== "undefined" && 
      localStorage.getItem(sessionKey) !== null) {
        localData = JSON.parse(localStorage.getItem(sessionKey));
      }
      
      if(localData != null) {
        return localData[key];
      }
      return null;
    } catch (e) {
      console.error("Error getting data from localStorage", e);
      return null;
    }
  }

  deleteCookie(key: string) {
    try {
      this.cookieService.delete(key, '/');
    } catch (e) {
      console.error("Error saving to sessionStorage", e);
    }
  
  }

  delete(key: string) {
    this.deleteCookie(key);
  }

  deleteSession(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error("Error saving to sessionStorage", e);
    }
  }
}
