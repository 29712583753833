import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GetOrderDetailsQuery } from 'src/app/services/graphql/getOrderDetails.graphql';
import { GetPaymentInvoiceDataQuery } from 'src/app/services/graphql/getPaymentInvoiceData.graphql';
import { GetAddressQuery } from 'src/app/services/graphql/getAddress.graphql';
import { Apollo } from 'apollo-angular';
import { SessionService } from 'src/app/services/session.service';
import { BehaviorSubject } from "rxjs";
import { EventConstants, ApplicationConstants } from 'src/app/constants/app.constants';
import { RejectOffereeLockMutation } from 'src/app/services/graphql/rejectOffereeLock.graphql';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { AddOfferLockRatingMutation } from 'src/app/services/graphql/addOfferLockRating.graphql';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CurencyPipe } from '../../../pipes/curency/curency.pipe';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface ProductOrder {
  shared_customer_id: string;
  price_paid: number;
  order_date: string;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbRatingConfig, CurencyPipe]
})

export class OrdersComponent implements OnInit {

  defaultProductImage = EventConstants.CONST_DEFAULT_OFFER_IMAGE;

  years = [];
  current_year = 0;
  offer_rating = [];
  pdfDocDefinitionTemplate = null;
  user_id = null;

  public productOrders = new BehaviorSubject<Array<ProductOrder>>(
    []
  );

  public rejection_reason = new BehaviorSubject<string>(
    ""
  );

  constructor(
    private getOrderDetailsQuery: GetOrderDetailsQuery,
    private apollo: Apollo,
    private sessionService: SessionService,
    private rejectOffereeLockMutation: RejectOffereeLockMutation,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private config: NgbRatingConfig,
    private addOfferLockRatingMutation: AddOfferLockRatingMutation,
    private getPaymentInvoiceDataQuery: GetPaymentInvoiceDataQuery,
    private getAddressQuery: GetAddressQuery,
    private currencyPipe: CurencyPipe
  ) {
    config.max = 5;
    config.readonly = true;
   }

  ngOnInit() {
    this.user_id = this.sessionService.getKeyValues("token", "id");
    let current_year = new Date().getFullYear();
    var current_date = new Date();

    current_date.setDate(current_date.getDate() - (3650));
    let past_year = current_date.getFullYear();

    for (let i = current_year; i >= past_year; i--) {
      this.years.push(i);
    }

    this.getOrderDetails(current_year);

    this.current_year = current_year;

    this.pdfInit();
  }

  pdfInit() {
    this.pdfDocDefinitionTemplate = {
      footer: {
      columns: [
        { text: 'Zigwik Pvt. Ltd.,\nPlease note that this invoice is not demand for payment.', style: 'footercenter' }
      ]
    },
    content: [
  {
              layout: "noBorders",
              style: "headertable",
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 0,
              widths: ['auto', '*'],
              body: [
                [ {image: ApplicationConstants.CONST_ZIGWIK_LOGO_BASE64
                ,width: 162,
                height: 73}, {
        stack: [
          {   style: "headerright",
              text: [
              {text: 'Tax Invoice/Bill of Supply', style: 'headerrightbold'},
              {text: ' \n(Original for Recipient)', style: 'headerright'},
            ]
          }
        ]
      }
               ]
              ]
            }
          },
          {text: "\n\n\n"},
          {
              layout: "noBorders",
              style: "headertable",
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 0,
              widths: ["auto", "*"],
              body: [
                [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: 'Offer Provisioned By :', style: 'headerleftbold'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: 'Billing Address :', style: 'headerrightbold'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: 'Zigwik Pvt. Ltd.,', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: '<<offeree_address_line_1>>, <<offeree_address_line_2>>,', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: '<<offeror_address_line_1>>, <<offeror_address_line_2>>,', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: '<<offeree_landmark>>, <<offeree_area>>,', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
          text: [
          {text: '<<offeror_landmark>>, <<offeror_area>>,', style: 'headerleft'}
        ]
      }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: '<<offeree_pin_code>>, <<offeree_state>>,', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: '<<offeror_pin_code>>, <<offeror_state>>,', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: '<<offeree_country>>', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
              {   style: "headerleft",
                  text: [
                  {text: '<<offeror_country>>', style: 'headerleft'}
                ]
              },
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
                  {text: 'State/UT Code :', style: 'headerrightboldsmall'},
              {text: ' <<gst_state_or_ut_code>>', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: '\n\n', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: '', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: 'PAN No : ', style: 'headerleftboldsmall'},
              {text: '<<offeror_pan_card>>', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: 'Place of Service : ', style: 'headerrightboldsmall'},
              {text: '<<state_of_offeror>>', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: 'GST Registration No : ', style: 'headerleftboldsmall'},
              {text: '<<gst_no>>', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: 'Place of delivery : ', style: 'headerrightboldsmall'},
              {text: '<<state_of_offeree>>', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: '\n\n', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: '', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: 'Order Number : ', style: 'headerleftboldsmall'},
              {text: '<<shared_order_id>>', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: 'Invoice Number : ', style: 'headerrightboldsmall'},
              {text: '<<shared_invoice_id>>', style: 'headerright'}
            ]
          }
        ]
      }
               ],
               [ {
        stack: [
          {   style: "headerleft",
              text: [
              {text: 'Order Date : ', style: 'headerleftboldsmall'},
              {text: '<<order_date>>', style: 'headerleft'}
            ]
          }
        ]
      }, {
        stack: [
          {   style: "headerrighttext",
              text: [
              {text: 'Invoice Date : ', style: 'headerrightboldsmall'},
              {text: '<<invoice_date>>', style: 'headerright'}
            ]
          }
        ]
      }
               ],
              ]
            }
          },
          {text: "\n\n\n"},
          {
        style: 'tableExample',
        color: '#444',
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'],
          headerRows: 1,
          // keepWithHeaderRows: 1,
          body: [
              
            ['Sr No.', 'Title', 'Net Amount', 'Tax Rate', 'Tax Type', 'Tax Amount', 'Total Amount'],
            [{rowSpan: 2, text: '1'}, {rowSpan: 2, text: '<<title>>'}, {rowSpan: 2, text: '<<net_amount>>'}, '<<tax_rate>>', '<<tax_type>>', '<<tax_amount>>', {rowSpan: 2, text: '<<total_amount>>'}],
            ['', '', '', '<<tax_rate>>', '<<tax_type>>', '<<tax_amount>>', ''],
            [{colSpan: 5, text: 'TOTAL'}, '', '', '', '', {text: "<<total_tax>>", style: 'headerleftboldsmall'}, {text: "<<total_amount>>", style: 'headerleftboldsmall'}],
            [{colSpan: 7, text: 'Amount in words: \n<<amount_in_words>>'}, '', '', '', '', '', ''],
          ]
        }
      },
      {text: "\n\nWhether tax is payable under reverse charge : No"},
      {text: "\n\n\n\n"},
      { text: 'The offer is provided by <<Offeror_name>>. Zigwik is generating this invoice as it is providing platform to manage such offer for the Offeror. Zigwik is not liable for any conflict or issues related to quality of service for Offer. You can find the profile of the Offeror here: https://zigwik.com/public-profile/<<Offeror_id>>', style: 'textsmall' }
    ],
    styles: {
        headertable: {
            margin: [0, 0, 0, 0]
        },
        headerrightbold: {
            fontSize: 14,
        bold: true,
        alignment: 'right'
        },
        headerrightboldsmall: {
            fontSize: 12,
        bold: true,
        alignment: 'right'
        },
        headerleftbold: {
            fontSize: 14,
        bold: true,
        alignment: 'left'
        },
        headerleftboldsmall: {
            fontSize: 12,
        bold: true,
        alignment: 'left'
        },
        headerright: {
            fontSize: 12,
        alignment: 'right',
        margin: [160, 0, 0, 0]
        },
        headerrighttext: {
            fontSize: 12,
        alignment: 'right',
        margin: [40, 0, 0, 0]
        },
        headerleft: {
            fontSize: 12,
        alignment: 'left'
        },
        footercenter: {
            fontSize: 10,
        alignment: 'center'
        },
        textsmall: {
            fontSize: 8
        }
    }
    
  };
  }

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j] + "");
                    n_array[i] = 0;
                }
            }
        }
        var value = 0;
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
}

convertNumberWithDecimalToWords(n) {
  var nums = n.toString().split('.')
  var whole = this.convertNumberToWords(nums[0])
  if (nums.length == 2) {
      var fraction = this.convertNumberToWords(nums[1])
      return whole + 'and ' + fraction;
  } else {
      return whole;
  }
}

  pdfGenerator(offer_title, lock_id, order_id) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    var email = this.sessionService.getLocalKeyValues("user", "email");
    // let pan_card = this.sessionService.getKeyValues("user", "pan_card");
    let pan_card = ApplicationConstants.CONST_ZIGWIK_PAN_CARD;

    var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

    if(email === null || email === undefined || email === "") {
      email = anonymousEmail;
    }

    this.apollo
    .query({
      query: this.getPaymentInvoiceDataQuery.document,
      variables: {
        id_token: token,
        user_email: email,
        order_id: order_id,
        lock_id: lock_id,
        user_type: "offeree"
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if (data["getInvoiceData"]) {
          var invoiceData = data["getInvoiceData"];
          this.pdfDocDefinitionTemplate.content[7].text = "'The offer is provided by " + data["getInvoiceData"].offeror_fullname + ". Zigwik is generating this invoice as it is providing platform to manage such offer for the Offeror. Zigwik is not liable for any conflict or issues related to quality of the offer. You can find the profile of the Offeror " + data["getInvoiceData"].offeror_fullname + " here: https://zigwik.com/public-profile/" + data["getInvoiceData"].offeror_id + ". For further details please go through our Terms and Conditions information at https://zigwik.com.'";
          this.pdfDocDefinitionTemplate.content[2].table.body[7][0].stack[0].text[1].text = pan_card;
          this.pdfDocDefinitionTemplate.content[2].table.body[10][0].stack[0].text[1].text = data["getInvoiceData"].shared_order_id;
          this.pdfDocDefinitionTemplate.content[2].table.body[10][1].stack[0].text[1].text = data["getInvoiceData"].shared_invoice_id;
          this.pdfDocDefinitionTemplate.content[2].table.body[11][1].stack[0].text[1].text = (new Date(data["getInvoiceData"].created_at)).toDateString();
          this.pdfDocDefinitionTemplate.content[2].table.body[11][0].stack[0].text[1].text = (new Date(data["getInvoiceData"].created_at)).toDateString();

          let taxCount = invoiceData.tax_data.length;

          var ct = 1;
          invoiceData.tax_data.forEach(tax => {
            if(ct === 1) {
              this.pdfDocDefinitionTemplate.content[4].table.body[ct] = [{rowSpan: taxCount, text: ct}, {rowSpan: taxCount, text: offer_title}, {rowSpan: taxCount, text: this.currencyPipe.transform(invoiceData.price_after_tax)}, tax.tax_percentage + "%", tax.tax_type, this.currencyPipe.transform(tax.price_tax), {rowSpan: taxCount, text: this.currencyPipe.transform(invoiceData.price_before_tax)}];
            } else {
              this.pdfDocDefinitionTemplate.content[4].table.body[ct] = ['', '', '', tax.tax_percentage + "%", tax.tax_type, this.currencyPipe.transform(tax.price_tax), ''];
            }
            ct += 1;
          });

          this.pdfDocDefinitionTemplate.content[4].table.body[ct] = [{colSpan: 5, text: 'TOTAL'}, '', '', '', '', {text: this.currencyPipe.transform(invoiceData.price_before_tax - invoiceData.price_after_tax), style: 'headerleftboldsmall'}, {text: this.currencyPipe.transform(invoiceData.price_before_tax), style: 'headerleftboldsmall'}];
          ct += 1;
          this.pdfDocDefinitionTemplate.content[4].table.body[ct] = [{colSpan: 7, text: 'Amount in words: \n' + this.convertNumberWithDecimalToWords(invoiceData.price_before_tax)}, '', '', '', '', '', ''];
          ct += 1;
          this.pdfDocDefinitionTemplate.content[4].table.body[ct] = [{colSpan: 7, text: ''}, '', '', '', '', '', ''];

          this.apollo
          .query({
            query: this.getAddressQuery.document,
            variables: {
              id_token: token,
              user_email: email,
              address_id: invoiceData.offeror_address_id
            },
            fetchPolicy: "network-only"
          })
          .subscribe(
            ({ data }) => {
              if (data["getAddress"]) {
                var offerorAddress = data["getAddress"];

                var address_line = "";
                var address_line2 = "";
                if(offerorAddress.address_line1 == null || offerorAddress.address_line1 == "") {
                  address_line = offerorAddress.address_line2;
                } else if(offerorAddress.address_line2 == null || offerorAddress.address_line2 == "") {
                  address_line = offerorAddress.address_line1;
                } else {
                  address_line = offerorAddress.address_line1 + ", " + offerorAddress.address_line2;
                }

                if(offerorAddress.landmark == null || offerorAddress.landmark == "") {
                  address_line2 = offerorAddress.area_name;
                } else {
                  address_line2 = offerorAddress.landmark + ", " + offerorAddress.area_name;
                }

                this.pdfDocDefinitionTemplate.content[2].table.body[2][0].stack[0].text[0].text = ApplicationConstants.CONST_ZIGWIK_ADDRESS_1;
                this.pdfDocDefinitionTemplate.content[2].table.body[3][0].stack[0].text[0].text = ApplicationConstants.CONST_ZIGWIK_ADDRESS_2;
                this.pdfDocDefinitionTemplate.content[2].table.body[4][0].stack[0].text[0].text = "Pin: " + ApplicationConstants.CONST_ZIGWIK_ADDRESS_PIN_CODE + ", " + ApplicationConstants.CONST_ZIGWIK_ADDRESS_STATE;
                this.pdfDocDefinitionTemplate.content[2].table.body[5][0].stack[0].text[0].text = ApplicationConstants.CONST_ZIGWIK_ADDRESS_COUNTRY;
                this.pdfDocDefinitionTemplate.content[2].table.body[7][1].stack[0].text[1].text = ApplicationConstants.CONST_ZIGWIK_ADDRESS_STATE;
                this.pdfDocDefinitionTemplate.content[2].table.body[8][0].stack[0].text[1].text = ApplicationConstants.CONST_ZIGWIK_GST_GLOBAL_NUMBER;

                this.apollo
                .query({
                  query: this.getAddressQuery.document,
                  variables: {
                    id_token: token,
                    user_email: email,
                    address_id: invoiceData.offeree_address_id
                  },
                  fetchPolicy: "network-only"
                })
                .subscribe(
                  ({ data }) => {
                    if (data["getAddress"]) {
                      var offereeAddress = data["getAddress"];

                      var address_line = "";
                      var address_line2 = "";
                      if(offereeAddress.address_line1 == null || offereeAddress.address_line1 == "") {
                        address_line = offereeAddress.address_line2;
                      } else if(offereeAddress.address_line2 == null || offereeAddress.address_line2 == "") {
                        address_line = offereeAddress.address_line1;
                      } else {
                        address_line = offereeAddress.address_line1 + ", " + offereeAddress.address_line2;
                      }

                      if(offereeAddress.landmark == null || offereeAddress.landmark == "") {
                        address_line2 = offereeAddress.area_name;
                      } else {
                        address_line2 = offereeAddress.landmark + ", " + offereeAddress.area_name;
                      }

                      this.pdfDocDefinitionTemplate.content[2].table.body[1][1].stack[0].text[0].text = address_line;
                      this.pdfDocDefinitionTemplate.content[2].table.body[2][1].stack[0].text[0].text = address_line2;
                      this.pdfDocDefinitionTemplate.content[2].table.body[3][1].stack[0].text[0].text = "Pin: " + offereeAddress.pin_code + ", " + offereeAddress.state;
                      this.pdfDocDefinitionTemplate.content[2].table.body[4][1].stack[0].text[0].text = offereeAddress.country;
                      this.pdfDocDefinitionTemplate.content[2].table.body[5][1].stack[0].text[1].text = offereeAddress.tax_code;
                      this.pdfDocDefinitionTemplate.content[2].table.body[8][1].stack[0].text[1].text = offereeAddress.state;

                      pdfMake.createPdf(this.pdfDocDefinitionTemplate).download('invoice.pdf');
                    }
                  },
                  (error) => {
                    console.log("error");
                  }
                );


              }
            },
            (error) => {
              console.log("error");
            }
          );

        } else {
          this.toastr.error("Not able to generate the invoice.");
        }
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  onRateChange($evt, lock_id) {
    this.offer_rating[lock_id] = $evt;
  }

  rateOffer(lock_id) {

    if(this.offer_rating[lock_id] == undefined || this.offer_rating[lock_id] <= 0) {
      alert("No rating set. Please specify a rating before continuing.");
    } else {
      let token = this.sessionService.getKeyValues("token", "id_token");
      var email = this.sessionService.getLocalKeyValues("user", "email");
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if(email === null || email === undefined || email === "") {
        email = anonymousEmail;
      }
  
      this.apollo
      .mutate({
        mutation: this.addOfferLockRatingMutation.document,
        variables: {
          user_email: email,
          id_token: token,
          lock_id: lock_id,
          comment: (document.getElementById("ratingtext" + lock_id) as HTMLInputElement).value,
          rating: this.offer_rating[lock_id] * 2
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Rating successfully added.");
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
    
  }

  rejectOffer(order_id, lock_id, refund_check) {
    this.rejection_reason.next("");
    let token = this.sessionService.getKeyValues("token", "id_token");
    var email = this.sessionService.getLocalKeyValues("user", "email");
    var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

    if(email === null || email === undefined || email === "") {
      email = anonymousEmail;
    }

    this.apollo
    .mutate({
      mutation: this.rejectOffereeLockMutation.document,
      variables: {
        offeree_email: email,
        id_token: token,
        order_id: order_id,
        lock_id: lock_id,
        refund_check: refund_check
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        this.toastr.success("Rejected the Offer.");
        this.modalService.dismissAll("");
        this.getOrderDetails(this.current_year);
      },
      (error) => {
        this.rejection_reason.next(error.message);
      }
    );
  }

  openModal(content, order_id, lock_id) {
    var modal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    this.rejectOffer(order_id, lock_id, true);
  }

  closeModal() {
    this.modalService.dismissAll(""); 
  }

  getOrderDetails(year) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    var email = this.sessionService.getLocalKeyValues("user", "email");
    var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

    if(email === null || email === undefined || email === "") {
      email = anonymousEmail;
    }

    this.apollo
    .query({
      query: this.getOrderDetailsQuery.document,
      variables: {
        year: year,
        id_token: token,
        user_email: email,
        user_type: "offeree"
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if (data["getProductOrders"]) {
          var temp_array = [];
          data["getProductOrders"].forEach(order => {
            temp_array.push({
              order_id: order["shared_order_id"],
              original_order_id: order["order_id"],
              order_date: order["order_date"],
              price_paid: order["price_paid"],
              locks: order["locks"]
            });
            order["locks"].forEach(lock => {
              if(lock["rating"] != null) {
                this.offer_rating[lock["id"]] = lock["rating"] / 2;
              }
            });
          });

          this.productOrders.next(temp_array);
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

}
