import { Injectable } from "@angular/core";
import { LoginUserMutation } from "../services/graphql/login.graphql";
import { GetUserQuery } from "../services/graphql/getuser.graphql";
import { SessionService } from "./session.service";
import { CreateAnonymousUserMutation } from '../services/graphql/createAnonymousUser.graphql';
import { Apollo } from 'apollo-angular';
import { SessionConstants } from "src/app/constants/app.constants";


@Injectable({
    providedIn: "root",
  })
  
export class LoginService {
    constructor(
        private apollo: Apollo,
        private loginUserMutation: LoginUserMutation,
        private createAnonymousUserMutation: CreateAnonymousUserMutation,
        private getUserQuery: GetUserQuery,
        private sessionService: SessionService
    ) {}

    async loginAnonymousUser() {
        var token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
        if(token !== null && token !== undefined) {
            return;
        }
        var anonymousUser = await this.apollo
          .mutate({
            mutation: this.createAnonymousUserMutation.document,
            fetchPolicy: "network-only"
          }).toPromise();

          var data = [];
          data["loginUser"] = anonymousUser["data"]["createAnonymousUser"];

          if(anonymousUser["data"]["createAnonymousUser"] !== null) {
            data["loginUser"].email = "";
            data["loginUser"].valid_user = false;
            data["loginUser"]["login_time"] = (new Date()).getTime();
            this.sessionService.set(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, data["loginUser"]);
          }
      }
}  