<app-page-loader></app-page-loader>
<app-header [reverseLoginCheck]=true></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [reverseLoginCheck]=true></app-header-mobile>

<!-- Signup Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Signup" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>

<section class="pro-content login-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Create Account" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <span class="is-size-7">
        {{ "For email id, please put only lowercase characters." | translate }}<br /><br />
      </span>
        <div class="registration-process">
          <form [formGroup]="signUpForm">
            <div
              *ngIf="errorMessage"
              class="input-group col-12 alert alert-danger alert-dismissible"
            >
              <a
                href="#"
                class="close"
                data-dismiss="alert"
                aria-label="close"
                (click)="alertCloseHandler()"
                >&times;</a
              >
              {{ errorMessage }}
            </div>
            <div class="from-group row mb-4">
              <div class="input-group col-12">
                Date Of Birth (*):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input 
                  type="date" 
                                    name="birthdate"
                  class="form-control"
                  formControlName="birthdate"
                  [formControl]="birthdate"
                  [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }"
                  required>
              </div>
            </div>
            <div class="from-group row mb-4" *ngIf="referral_code_module === 'True'">
              <div class="input-group col-12">
                Add Referral Code:&nbsp;
                <input
                  id="referral_code" 
                  type="text"
                  name="referral_code"
                  class="form-control"
                  formControlName="referral_code"
                  [formControl]="referral_code"
                  placeholder="Referral Code"
                  (keyup)="referralCodeOnKey($event)"
                />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 is-size-7">
                With your registration you agree to our <a [routerLink]="'/terms'">terms of service</a> including all policies mentioned in "Related Articles" section.
              </div>
            </div>
            <div class="row mb-4">
              <div class="registration-socials">
                <p class="text-center">
                  {{ "Access Your Account Through Your Social Networks" | translate }}
                </p>
                <br />
                <div class="btn-group">
                  <button [disabled]="isSubmitBtnDisabled" type="button" class="btn btn-google mr-2 swipe-to-top" (click)="googleLogin()">
                    <i class="fab fa-google-plus-g"></i>&nbsp;Google
                  </button>
                  <!-- <button [disabled]="isSubmitBtnDisabled" type="button" class="btn btn-facebook mr-2 swipe-to-top" (click)="facebookLogin()">
                    <i class="fab fa-facebook-f"></i>&nbsp;Facebook
                  </button> -->
                  <!-- <button [disabled]="isSubmitBtnDisabled" type="button" class="btn btn-twitter swipe-to-top" (click)="twitterLogin()">
                    <i class="fab fa-twitter"></i>&nbsp;Twitter
                  </button> -->
                </div>
              </div>
            </div>
            <div class="from-group row mb-4">
              <div class="input-group col-12">
                <input
                  type="text"
                  name="firstname"
                  class="form-control"
                  formControlName="firstname"
                  placeholder="First name (*)"
                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                />
                <input
                  type="text"
                  name="lastname"
                  class="form-control"
                  formControlName="lastname"
                  placeholder="Last name (*)"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                />
              </div>
            </div>
            <div class="from-group row mb-4">
              <div class="input-group col-12">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  formControlName="email"
                  placeholder="Email (*) - lowercase only"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <input
                  type="text"
                  name="mobile"
                  class="form-control"
                  formControlName="mobile"
                  placeholder="Mobile (*)"
                  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                />
              </div>
            </div>
            <div class="from-group row mb-4">
              <div class="input-group col-12">
                <input
                  type="password"
                  name="password"
                  class="form-control"
                  formControlName="password"
                  placeholder="Password (*)"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                />
              </div>
            </div>
            
            


            
            
            
            <!-- <div class="from-group row mb-4">
              Description
              <div class="input-group col-12">
                <app-markdown-text-editor [inputValue]="profileInputDescription" (newItemHtmlValue)="addHtmlDescription($event)" (newItemMarkdownValue)="addMarkdownDescription($event)"></app-markdown-text-editor>
              </div>
            </div> -->
            <div class="from-group">
              <button
                id = "createAccountButton"
                [disabled]="isSubmitBtnDisabled"
                type="button"
                class="btn btn-secondary swipe-to-top"
                (click)="createAccount()"
              >
                {{ "Create Account" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <h2>{{ "Already Registered?" | translate }}</h2>

        <div class="registration-process">
          <p>
            Login to create your own offer or to opt for an existing offer.
          </p>
          <form>
            <div class="mt-2 from-group">
              <a [routerLink]="'/login'" class="btn btn-primary swipe-to-top">{{
                "Login" | translate
              }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
