import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Observable, from, BehaviorSubject, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user = new BehaviorSubject(this.auth.authState);
  catogeryList = new BehaviorSubject<any>(null);
  allCatogeryList = new BehaviorSubject<any>(null);
  filetrData = new Subject<any>()
  user$: Observable<firebase.User | null> = this.user.pipe(
    switchMap((user) => user)
  );

  constructor(private readonly auth: AngularFireAuth) {
  }
  loginWithPopup(type = "google") {
    this.logout();
    if(type == "google") {
      return from(
        this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
        );
    }
  }

  loginWithRedirect(type = "twitter") {
    this.logout();
    if(type == "twitter") {
      return from(
        this.auth.signInWithRedirect(new firebase.auth.TwitterAuthProvider())
        );
    } else if(type == "facebook") {
      return from(
        this.auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider())
        );
    }
  }

  logout() {
    return from(
      this.auth.signOut()
      );
  }
}
