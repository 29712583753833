import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class CreateUserMutation extends Mutation {
  document = gql`
    mutation createUserMutation(
      $first_name: String!
      $last_name: String!
      $phone: String!
      $email: String!
      $password: String
      $birth_date: Date!
      $terms_accepted: Boolean!
      $adhaar_card: String
      $verify_email: String
      $pan_card: String
      $refresh_token: String
      $referral_code: String
      $description: String
      $markdown_description: String
      $address_line1: String
      $address_line2: String
      $landmark: String
      $bank_code: String
      $account_number: String
      $area_name: String
      $pin_code: String
      $city: String
      $district: String
      $sso: String
      $firebase_user_id: String
      $state: String
    ) {
      createUser(
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        email: $email
        verify_email: $verify_email
        refresh_token: $refresh_token
        password: $password
        birth_date: $birth_date
        terms_accepted: $terms_accepted
        adhaar_card: $adhaar_card
        pan_card: $pan_card
        referral_code: $referral_code
        description: $description
        markdown_description: $markdown_description
        from_sso: $sso
        firebase_user_id: $firebase_user_id
        bank_code: $bank_code
        account_number: $account_number
        address_line1: $address_line1
        address_line2: $address_line2
        landmark: $landmark
        area_name: $area_name
        pin_code: $pin_code
        city: $city
        district: $district
        state: $state
      ) {
        creation_status
        token_type
        access_token
        id_token
        refresh_token
        expires_in
        id
        user_status
        is_email_verified
      }
    }
  `;
}
