<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<div class="public-profile">
<!-- Profile Content -->
<div class="container-fuild">
    <nav aria-label="breadcrumb">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/'">{{ "Home" | translate }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Public Profile" | translate }}
          </li>
        </ol>
      </div>
    </nav>
  </div>
  
  <div class="container bootstrap snippets bootdey">
    <div class="row">
      <div class="profile-nav col-md-3">
          <div class="panel">
              <div class="user-heading round">
                  <a href="#">
                      <img [src]="profileImageLink" alt="">
                  </a>
                  <h1>{{ this.userData["first_name"] }}&nbsp;{{ this.userData["last_name"] }}</h1>
              </div>
          </div>
      </div>
      <div class="profile-info col-md-9">
          <div class="panel">
              <div class="bio-graph-heading">
                  Welcome!
              </div>
              <div class="m-2 panel-body bio-graph-info" *ngIf="this.userData != null">
                  <h1>Bio Graph</h1>
                  <div class="row">
                      <div class="bio-row">
                          <p><i><span>First Name </span></i>: {{ this.userData["first_name"] }}</p>
                      </div>
                      <div class="bio-row">
                          <p><i><span>Last Name </span></i>: {{ this.userData["last_name"] }}</p>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col">
                        <p><i><span>Description </span></i>: </p><div [innerHTML]="this.userData['description']"></div>
                    </div>
                </div>
                <br />
              </div>
          </div>
      </div>
          <div class="container">
              <div class="products-area">
                <div *ngIf="noProduct == false" class="m-6 is-size-3">{{"Offers from " | translate}}{{ this.userData["first_name"] }}</div>
                <div class="row">
                  <div
                    class="col-12 col-md-6 col-lg-4 col-xl-4 mt-5"
                    *ngFor="let product of this.homeProducts"
                  >
                    <app-product-card [data]="product"></app-product-card>
                  </div>
                </div>
              </div>
            </div>
    </div>
    </div>
</div>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<!-- <app-accept-cookie></app-accept-cookie> -->
<app-back-to-top></app-back-to-top>
<!-- <app-switcher></app-switcher> -->