<div class="serach-container">
  <form [formGroup]="productForm" class="row form-inline">
    <div class="search-field-module">
      <div class="search-form-fields">
        <div class="search-field-wrap filter-fields">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            ngbDatepicker
            #d1="ngbDatepicker"
            [(ngModel)]="model_start_date"
            formControlName="start_date"
            (click)="d1.toggle()"
            (dateSelect)="onDateSelection($event, 'start_date')"
          />
          <span class="icon-tooltip" data-tooltip="Start Date">
            <i class="fas fa-circle-info"></i>
          </span>
        </div>
        <div class="search-field-wrap filter-fields">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            [(ngModel)]="model_end_date"
            ngbDatepicker
            #d2="ngbDatepicker"
            formControlName="end_date"
            (click)="d2.toggle()"
            (dateSelect)="onDateSelection($event, 'end_date')"
          />
          <span class="icon-tooltip" data-tooltip="End Date">
            <i class="fas fa-circle-info"></i>
          </span>
        </div>
        <div class="search-field-select filter-fields">
          <div class="dropdown">
            <select
              [(ngModel)]="categoryValue"
              class="dropdown-toggle form-control"
              formControlName="category"
            >
              <option value="">{{ "All Categories" | translate }}</option>
              <option *ngFor="let category of categoryData" [value]="category">
                {{ category }}
              </option>
            </select>
          </div>
        </div>
        <div class="search-field-select filter-fields">
          <div class="dropdown">
            <select
              [(ngModel)]="scheduleTypeValue"
              class="dropdown-toggle form-control"
              formControlName="scheduletype"
              name="scheduletype"
            >
              <option value="" selected>
                {{ "All Schedules" | translate }}
              </option>
              <option value="hourly">{{ "hourly" | translate }}</option>
              <option value="daily">{{ "daily" | translate }}</option>
              <option value="weekly">{{ "weekly" | translate }}</option>
              <option value="monthly">{{ "monthly" | translate }}</option>
              <option value="custom">{{ "custom" | translate }}</option>
            </select>
          </div>
        </div>
        <div class="search-field-wrap filter-fields">
          <input
            type="search"
            class="form-control"
            placeholder="{{ 'Search Offers...' | translate }}"
            data-toggle="tooltip"
            data-placement="bottom"
            formControlName="title"
            name="title"
            value="title"
          />
        </div>

        <button
          type="submit"
          class="btn btn-secondary filter-fields header-search-btn"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Search Offers"
          routerLink="/search"
          (click)="productList(true)"
        >
          <i class="fa fa-search"></i>
          search
        </button>
      </div>
    </div>
  </form>
</div>
