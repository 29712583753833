<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true [withAnonymous]=true loginRedirectUrl="my-orders"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true [withAnonymous]=true loginRedirectUrl="my-orders"></app-header-mobile>

<!--My Order Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Orders" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content order-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Order Page" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3 d-block d-xl-block">
        <app-user-sidebar [inputUserId]="user_id"></app-user-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <div class="dropdown mb-2">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Select year
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="this.getOrderDetails(year)" *ngFor="let year of years">{{year}}</a>
          </div>
        </div>


        <div class="container" *ngIf="(this.productOrders | async)?.length <= 0">
          No Orders found.
        </div>
        <div class="orderload">
          <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
                <div class="container" *ngIf="(this.productOrders | async)?.length > 0">
                  <table cellpadding="0" cellspacing="0" border="0" class="dataTable table table-bordered" id="example">
                  <thead>
                      <tr>
                        <table class="table border-0">
                          <tr class="border-0">
                            <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th>Order Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th>Order Date&nbsp;&nbsp;</th>
                            <th>Cost&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                          </tr>
                        </table>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let order of productOrders | async">
                          <td class= "control center">
                            <table class="table border-0">
                              <tbody>
                                <tr class="border-0">
                                  <td>
                                    <a class="border-0" data-toggle="collapse" href="#orderDetail_{{order['order_id']}}" id="offerorDesc" role="button" aria-expanded="false">
                                      <button type="button" class="btn-sm border-0">
                                        <span class="fas fa-chevron-down"></span>
                                      </button>
                                    </a>
                                  </td>
                                  <td>{{order["order_id"]}}</td>
                                  <td>{{order["order_date"]}}</td>
                                  <td>{{order["price_paid"] | curency}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table border-0">
          <tbody>
            <tr class="border-0">
              <td>
                <div class="col-md-12 icon-box border-0" data-aos="fade-up" data-aos-delay="500">
                <div class="collapse border-0" id="orderDetail_{{order['order_id']}}">
                  <div class="card card-body border-0">



                    <table class="table top-table">
                      <tbody>
                        <tr class="d-flex" *ngFor="let data of this.order['locks']">
                          <td>
                            <table class="table border-0" width="100%">
                              <tbody>
                                <tr class="border-0">
                                  <td class="col-12 col-md-2">
                                    <img
                                    *ngIf="data.product_image_link != null" 
                                    class="img-fluid"
                                    src="{{data.product_image_link}}"
                                    alt="Product Image"
                                  />
                                  <img
                                    *ngIf="data.product_image_link == null" 
                                    class="img-fluid"
                                    src="{{defaultProductImage}}"
                                    alt="Product Image"
                                  />
                                  </td>
                                  <td class="col-12 col-md-4">
                                    <div class="item-detail">
                                      <span class="pro-info">&nbsp;{{data.category_name}}&nbsp;&nbsp;&nbsp;</span>
                                      <h2 class="pro-title">
                                        <a [routerLink]="['/offer/', data.product_id]">
                                          &nbsp;{{ data.title | translate }}
                                        </a>&nbsp;&nbsp;&nbsp;
                                      </h2>
                                      <div class="item-attributes">&nbsp;&nbsp;&nbsp;</div>
                                      <div class="item-controls">
                                        <a class="border-0" data-toggle="collapse" href="#offerorDetail_{{data.id}}" id="offerorDesc" role="button" aria-expanded="false">
                                          <button type="button" class="btn-sm border-0">
                                            <span class="fas fa-chevron-down"></span>
                                          </button>
                                      </a>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="col-12 col-md-2">
                                    <span class="item-price">&nbsp;&nbsp;&nbsp;{{
                                      data.price | curency
                                    }}&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td *ngIf="data['lock_type'] == 'hard_lock'">
                                    <div class="container">
                                      <div class="row">
                                        <div class="slot-pending_approval p-1">
                                          Pending Approval
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td *ngIf="data['lock_type'] == 'confirmed_lock'">
                                    <div class="container">
                                      <div class="row">
                                        <div class="slot-confirmed p-1">
                                          Confirmed
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td *ngIf="data['lock_type'] == 'offeree_grievance_refund_lock'">
                                    <div class="container">
                                      <div class="row">
                                        <div class="slot-rejected p-1">
                                          Complaint Filed
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td *ngIf="data['lock_type'] == 'auto_rejected_hard_lock' || data['lock_type'] == 'offeror_rejected_hard_lock'">
                                    <div class="container">
                                      <div class="row">
                                        <div class="slot-rejected p-1">
                                          Rejected by Offeror
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td *ngIf="data['lock_type'] == 'offeree_rejected_paid_lock_half_refund' || data['lock_type'] == 'offeree_rejected_paid_lock_full_refund' || data['lock_type'] == 'offeree_rejected_paid_lock_no_refund'">
                                    <div class="container">
                                      <div class="row">
                                        <div class="slot-cancelled p-1">
                                          Cancelled
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td *ngIf="data['lock_type'] == 'lock_fulfilled'">
                                    <div class="container">
                                      <div class="row">
                                        <div class="slot-fulfilled p-1">
                                          Fulfilled
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr></tbody></table>
                                <table class="table border-0">
                                  <tbody>
                                    <tr class="border-0">
                                      <td>
                                        <div class="col-md-12 icon-box border-0" data-aos="fade-up" data-aos-delay="500">
                                        <div class="collapse border-0" id="offerorDetail_{{data.id}}">
                                          <div class="card card-body border-0">
                                            <table class="w-auto border-0">
                                              <tr class="border-0">
                                                <th width="30%">Start&nbsp;date:</th>
                                                <td width="70%" valign="bottom">{{ data.start_date }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">End&nbsp;date:</th>
                                                <td width="70%" valign="bottom">{{ data.end_date }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">Schedule&nbsp;Type:</th>
                                                <td width="70%" valign="bottom">{{ data.schedule_frequency }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">Additional&nbsp;Guidelines:</th>
                                                <td width="70%" valign="bottom">{{ data.schedule_guidelines }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">Maximum&nbsp;offer&nbsp;count:</th>
                                                <td width="70%" valign="bottom">{{ data.product_count }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">Approval&nbsp;days&nbsp;for&nbsp;Offer:</th>
                                                <td width="70%" valign="bottom">{{ data.buffer_days }}</td>
                                              </tr>
                                              <tr class="border-0" *ngIf="data['refund_amount'] != null">
                                                <th width="30%">Refund&nbsp;amount:</th>
                                                <td width="70%" valign="bottom">{{ data.refund_amount | curency }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">Full&nbsp;refund&nbsp;before:</th>
                                                <td width="70%" valign="bottom">{{ data.full_refund_date }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%">Half&nbsp;refund&nbsp;before:</th>
                                                <td width="70%" valign="bottom">{{ data.half_refund_date }}</td>
                                              </tr>
                                              <tr class="border-0">
                                                <th width="30%" colspan="2">Slot(s):</th>
                                              </tr>
                                              <tr class="border-0">
                                                <td width="70%" valign="bottom" colspan="2">
                                                  <table class="table table-bordered table-striped">
                                                      <tr>
                                                        <th>&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                        <th>Duration</th>
                                                        <th>Start&nbsp;time</th>
                                                        <th>End&nbsp;time</th>
                                                      </tr>
                                                    <tbody>
                                                      <tr *ngFor="let slot of data.slots">
                                                        <td>&nbsp;{{slot["actual_date"]}}</td>
                                                        <td>
                                                          {{slot["slot_hours"]}}&nbsp;hour&nbsp;{{slot["slot_minutes"]}}&nbsp;minute
                                                        </td>
                                                        <td>
                                                          <ng-template [ngIf]="slot['start_hour'].toString().length < 2">0{{slot["start_hour"]}}</ng-template><ng-template [ngIf]="slot['start_hour'].toString().length >= 2">{{slot["start_hour"]}}</ng-template>:<ng-template [ngIf]="slot['start_minute'].toString().length < 2">0{{slot["start_minute"]}}</ng-template><ng-template [ngIf]="slot['start_minute'].toString().length >= 2">{{slot["start_minute"]}}</ng-template>
                                                        </td>
                                                        <td>
                                                          <ng-template [ngIf]="slot['end_hour'].toString().length < 2">0{{slot["end_hour"]}}</ng-template><ng-template [ngIf]="slot['end_hour'].toString().length >= 2">{{slot["end_hour"]}}</ng-template>:<ng-template [ngIf]="slot['end_minute'].toString().length < 2">0{{slot["end_minute"]}}</ng-template><ng-template [ngIf]="slot['end_minute'].toString().length >= 2">{{slot["end_minute"]}}</ng-template>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr *ngIf="data['lock_type'] != 'auto_rejected_hard_lock' && data['lock_type'] != 'offeree_rejected_paid_lock_half_refund' && data['lock_type'] != 'offeree_rejected_paid_lock_full_refund' && data['lock_type'] != 'offeree_rejected_paid_lock_no_refund' && data['lock_type'] != 'offeror_rejected_hard_lock' && data['lock_type'] != 'lock_fulfilled' && data['lock_type'] != 'offeree_grievance_refund_lock'">
                                                <td>
                                                  <ng-template #confirmReject let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title" id="modal-basic-title">Offer Rejection</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      {{this.rejection_reason | async}}
                                                      <br /><br />
                                                      <button class="btn btn-dark btn-sm p-2" type="button" id="RejectOfferConfirmButton" aria-haspopup="true" aria-expanded="false" (click)="this.rejectOffer(order['original_order_id'], data['id'], false)">
                                                        Cancel Offer
                                                      </button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                      <button class="btn btn-dark btn-sm p-2" type="button" id="RejectOfferCancelButton" aria-haspopup="true" aria-expanded="false" (click)="this.closeModal()">
                                                        Cancel
                                                      </button>                                     
                                                    </div>
                                                  </ng-template>
                                                  <button class="btn btn-dark btn-sm" type="button" id="RejectOfferButton" aria-haspopup="true" aria-expanded="false" (click)="openModal(confirmReject, order['original_order_id'], data['id'])">
                                                    Cancel Offer
                                                  </button>
                                                </td>
                                                <td>
                                                  &nbsp;
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                        </td>
                                        </tr>
                                        </tbody>
                                        </table>







                                        <table class="table border-0">
                                          <tbody>
                                            <tr class="border-0" *ngIf="data['lock_type'] == 'lock_fulfilled'">
                                              <td>Rate the Offer - 
                                                <ngb-rating [max]="5" [rate]="data['rating'] / 2" [readonly]="false" (rateChange)="onRateChange($event, data['id'])">
                                                  <ng-template let-fill="fill" let-index="index">
                                                    <span class="rating-star" [class.rating-filled]="fill === 100" [class.rating-bad]="index < 3">&#9733;</span>
                                                  </ng-template>
                                                </ngb-rating>
                                              </td>
                                              </tr>
                                              <tr class="border-0" *ngIf="data['lock_type'] == 'lock_fulfilled'">
                                                <td>
                                                  <textarea [id]="'ratingtext' + data['id']" maxlength="300" matInput placeholder="Leave a comment...">{{ data["rating_comment"] }}</textarea>
                                                </td>
                                              </tr>
                                              <tr class="border-0" *ngIf="data['lock_type'] == 'lock_fulfilled'">
                                                <td>
                                                  <button id="ratingButton" class="button is-outline is-create is-light-gray" (click)="this.rateOffer(data['id'])">
                                                    {{ "Rate the Offer" | translate }}
                                                  </button>
                                                </td>
                                                </tr>
                                              <tr class="border-0">
                                                <td>
                                                  <button id="invoiceButton"
                class="button is-outline is-create is-light-gray" (click)="pdfGenerator(data['title'], data['id'], order['original_order_id'])"
              >
                {{ "Get Invoice" | translate }}
              </button>
                                                </td>
                                                </tr>
                                              </tbody>
                                              </table>






                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
                </td>
                </tr>
                </tbody>
                </table>
                            </td>
                      </tr>
                  </tbody>
                  </table> 
                      </div>
                      </div></div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>